import {
  Box,
  Container,
  Heading,
  Image,
  Link,
  SlideFade,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import CryptoStudent from "../images/cryptostudent.jpg";
import { usePinkMode } from "../utils";

export const CryptoStudents = () => {
  const containerRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      });
    });
    if (containerRef.current) observer.observe(containerRef.current);
  }, []);

  return (
    <Container
      ref={containerRef}
      mt={["60", "40"]}
      minH={"calc(80vh)"}
      maxW={"80%"}
    >
      <Stack spacing={"5"}>
        <SlideFade
          in={isVisible}
          transition={{ enter: { duration: 1, delay: 0.6 } }}
        >
          <Heading pb={"5"} size={"xl"}>
            Cryptostudents NFT collection
          </Heading>
        </SlideFade>
        <SlideFade
          in={isVisible}
          transition={{ enter: { duration: 1, delay: 1 } }}
        >
          <Text fontSize={"lg"} as="i">
            "Introducing Cryptostudents, an innovative NFT collection featuring
            10,000 unique pixel art illustrations inspired by students from all
            over the world. This collection, similar to the popular 'Bored Ape'
            NFTs, but with a student-focused twist, offers a fun and creative
            way to engage with the world of NFTs. Each piece in the collection
            is one-of-a-kind, providing collectors with the opportunity to own a
            truly unique piece of digital art. But this project is not just
            about entertainment. A portion of the revenue generated by sales of
            these NFTs will be directed towards investments in education. By
            purchasing a piece of this collection, not only will you own a
            unique and visually stunning NFT, but you will also be supporting a
            worthy cause. Investing in education is an investment in the future,
            and this project is a small way to make a big impact."
          </Text>
        </SlideFade>
        <SlideFade
          in={isVisible}
          transition={{ enter: { duration: 1, delay: 1.2 } }}
        >
          <Heading size={"lg"}>
            Check it out at{" "}
            <Link
              color={usePinkMode()}
              href={"https://opensea.io/collection/cryptostudents-nft"}
            >
              Opensea
            </Link>
          </Heading>
        </SlideFade>
        <SlideFade
          in={isVisible}
          transition={{ enter: { duration: 1, delay: 1.5 } }}
        >
          <Box pt={"10"} justifyContent={"center"} display={"flex"} w={"100%"}>
            <Stack>
              <Image
                borderRadius={"2xl"}
                alignSelf={"center"}
                boxSize={"sm"}
                objectFit="contain"
                src={CryptoStudent}
              />
            </Stack>
          </Box>
        </SlideFade>
      </Stack>
    </Container>
  );
};

export default CryptoStudents;
