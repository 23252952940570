import {
  ChakraProvider,
  extendTheme,
  StyleFunctionProps,
} from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";
import "@fontsource/rokkitt";
import CryptoStudents from "./components/CryptoStudents";
import Header from "./components/Header";
import Home from "./components/Home";
import { Projects } from "./components/Projects";
import ThankYou from "./components/ThankYou";
import WorkExperience from "./components/WorkExperience";

const config = {
  initialColorMode: "dark",
  useSystemColorMode: false,
};

const theme = extendTheme({
  config,
  styles: {
    global: (props: StyleFunctionProps) => ({
      body: {
        fontFamily: "body",
        color: mode("gray.800", "whiteAlpha.900")(props),
        bg: mode("white", "#181818")(props),
        lineHeight: "base",
      },
    }),
  },
});

export const App = () => (
  <ChakraProvider theme={theme}>
    <Header />
    <Home />
    <Projects />
    <CryptoStudents />
    <WorkExperience />
    <ThankYou />
  </ChakraProvider>
);
