import {
  Box,
  Container,
  Heading,
  Image,
  Link,
  SlideFade,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import BotExample from "../images/bot-example.png";

export const Projects = () => {
  const containerRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      });
    });
    if (containerRef.current) observer.observe(containerRef.current);
  }, []);

  return (
    <Container
      ref={containerRef}
      mt={["60", "40"]}
      minH={"calc(80vh)"}
      maxW={"80%"}
    >
      <Stack spacing={"5"}>
        <SlideFade in={isVisible} transition={{ enter: { duration: 1 } }}>
          <Heading
            color={useColorModeValue("pink.500", "pink")}
            fontFamily={"Rokkitt"}
            size={"xl"}
          >
            Projects
          </Heading>
        </SlideFade>
        <SlideFade
          in={isVisible}
          transition={{ enter: { duration: 1, delay: 0.6 } }}
        >
          <Heading pb={"5"} size={"xl"}>
            Discord bot for NFT profiting calculations and more!
          </Heading>
        </SlideFade>
        <SlideFade
          in={isVisible}
          transition={{ enter: { duration: 1, delay: 1 } }}
        >
          <Text fontSize={"lg"} as="i">
            "Introducing my latest project, an innovative Discord bot designed
            to streamline the process of calculating profit from NFT trading.
            The bot allows users to input multiple wallet addresses, and then
            utilizes a command to pass in a contract address for an NFT
            collection. From there, the bot retrieves important data such as the
            amount minted, the cost to mint, and any sales information. Using
            this data, the bot then calculates the profit made or potential
            profit using the collection's floor price. This bot is a valuable
            tool for anyone looking to stay on top of their NFT trading
            investments and make data-driven decisions."
          </Text>
        </SlideFade>
        <SlideFade
          in={isVisible}
          transition={{ enter: { duration: 1, delay: 1.2 } }}
        >
          <Heading size={"lg"}>
            You can check it out on <Link>EAT Discord Server</Link>
          </Heading>
        </SlideFade>
        <SlideFade
          in={isVisible}
          transition={{ enter: { duration: 1, delay: 1.5 } }}
        >
          <Box pt={"10"} justifyContent={"center"} display={"flex"} w={"100%"}>
            <Stack>
              <Image
                borderRadius={"2xl"}
                alignSelf={"center"}
                boxSize={"sm"}
                objectFit="contain"
                src={BotExample}
              ></Image>
              <Text textAlign={"center"}>
                Example of image generated by the bot.
              </Text>
            </Stack>
          </Box>
        </SlideFade>
      </Stack>
    </Container>
  );
};

export default Projects;
