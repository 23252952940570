import {
  Container,
  Heading,
  SlideFade,
  Stack,
  useColorModeValue,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import MintButton from "./MintButton";

export const ThankYou = () => {
  const containerRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      });
    });
    if (containerRef.current) observer.observe(containerRef.current);
  }, []);

  return (
    <Container
      ref={containerRef}
      mt={["60", "40"]}
      minH={"calc(80vh)"}
      maxW={"80%"}
    >
      <Stack spacing={"5"}>
        <SlideFade in={isVisible} transition={{ enter: { duration: 1 } }}>
          <Heading
            color={useColorModeValue("pink.500", "pink")}
            fontFamily={"Rokkitt"}
            size={"xl"}
          >
            Thank You! Time for minting
          </Heading>
        </SlideFade>
        <SlideFade
          in={isVisible}
          transition={{ enter: { duration: 1, delay: 0.6 } }}
        >
          <Heading pb={10} size={"lg"}>
            As a form of thanking you to take the time to read my resume, <br />
            I have created a NFT for you to mint for Free!! <br />
            All you have to do is connect your wallet and click the button
            below.
            <br />
            You will be redirected to the NFT marketplace and view your brand
            new NFT once you've minted it. Enjoy!
          </Heading>
          <MintButton />
        </SlideFade>
      </Stack>
    </Container>
  );
};

export default ThankYou;
