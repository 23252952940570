import { Button, Link } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import {
  useAccount,
  useConnect,
  useContractWrite,
  useNetwork,
  usePrepareContractWrite,
  useWaitForTransaction,
} from "wagmi";
import { InjectedConnector } from "wagmi/connectors/injected";
import BusinessCardABI from "../abis/BusinessCard.json";
import { RODRIGO_CONTRACT_ADDRESS } from "../config/constants";
import { exportedChains } from "../config/web3.config";

const MintButton = () => {
  const [unsupported, setUnsupported] = useState(false);
  const { address, isConnected } = useAccount();

  const { chain } = useNetwork();
  const { connect } = useConnect({
    connector: new InjectedConnector({
      chains: exportedChains,
      options: {
        shimChainChangedDisconnect: false,
      },
    }),
  });

  const { config } = usePrepareContractWrite({
    address: RODRIGO_CONTRACT_ADDRESS,
    abi: BusinessCardABI.abi,
    functionName: "mint",
  });

  const { write, data } = useContractWrite(config);

  const { isLoading, isSuccess } = useWaitForTransaction({
    hash: data?.hash,
  });

  const CustomToastWithLink = (hash: string) => (
    <div>
      <Link href={`https://goerli.etherscan.io/tx/${hash}`}>
        Sucessfuly Minted! Click here to view your tx in the explorer
      </Link>
    </div>
  );

  useEffect(() => {
    if (chain) {
      setUnsupported(chain.unsupported ?? false);
    }
  }, [chain]);

  useEffect(() => {
    if (isSuccess && data?.hash) {
      console.log("Minted NFT");
      toast(CustomToastWithLink(data?.hash), {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "dark",
        type: "success",
      });
    }
  }, [isSuccess, data?.hash]);

  const mintNFT = async () => {
    write?.();
  };

  if (isConnected && address) {
    return unsupported ? (
      <Button variant="solid" disabled colorScheme={"pink"}>
        Please switch to Goerli
      </Button>
    ) : (
      <>
        <Button
          onClick={() => mintNFT()}
          isLoading={isLoading}
          size={"lg"}
          variant="solid"
          colorScheme={"pink"}
          disabled={!write}
        >
          MINT
        </Button>
        <ToastContainer />
      </>
    );
  }
  return (
    <Button onClick={() => connect()} variant="solid" colorScheme={"pink"}>
      Connect Wallet
    </Button>
  );
};

export default MintButton;
