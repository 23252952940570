import { SmallCloseIcon } from "@chakra-ui/icons";
import {
  Box,
  Center,
  Container,
  Heading,
  List,
  ListIcon,
  ListItem,
  SlideFade,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { usePinkMode } from "../utils";

export const WorkExperience = () => {
  const containerRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      });
    });
    if (containerRef.current) observer.observe(containerRef.current);
  }, []);

  return (
    <Container
      ref={containerRef}
      mt={["60", "40"]}
      minH={"calc(80vh)"}
      maxW={"80%"}
    >
      <Stack spacing={"5"}>
        <SlideFade in={isVisible} transition={{ enter: { duration: 1 } }}>
          <Heading
            color={useColorModeValue("pink.500", "pink")}
            fontFamily={"Rokkitt"}
            size={"xl"}
          >
            Work Experience
          </Heading>
        </SlideFade>
        <SlideFade
          in={isVisible}
          transition={{ enter: { duration: 1, delay: 0.6 } }}
        >
          <Box display={"flex"} alignContent={"center"}>
            <Center w={["100%", "100%"]}>
              <Tabs isFitted w={["100%", "60%"]} colorScheme={"pink"}>
                <TabList
                  overflowX={"auto"}
                  overflowY={"hidden"}
                  fontFamily={"Rokkitt"}
                >
                  <Tab>Exacode</Tab>
                  <Tab>Loud</Tab>
                  <Tab>SNACKCLUB</Tab>
                  <Tab>Bitboundaire</Tab>
                </TabList>

                <TabPanels>
                  <TabPanel>
                    <Heading size={"md"}>
                      Web Development Internship
                      <Text display={"inline"} color={usePinkMode()}>
                        {" "}
                        @ Exacode
                      </Text>
                    </Heading>
                    <Text opacity={0.6}>Nov, 2020 - Sep, 2021</Text>
                    <List pt={"2"} spacing={3}>
                      <ListItem>
                        <ListIcon as={SmallCloseIcon} color={usePinkMode()} />
                        Java and Node.js development;
                      </ListItem>
                      <ListItem>
                        <ListIcon as={SmallCloseIcon} color={usePinkMode()} />
                        Creation of web applications;
                      </ListItem>
                      <ListItem>
                        <ListIcon as={SmallCloseIcon} color={usePinkMode()} />
                        Creation of APIs e Scrappers;
                      </ListItem>
                      <ListItem>
                        <ListIcon as={SmallCloseIcon} color={usePinkMode()} />
                        Creation and maintenance of PostgreSQL Database;
                      </ListItem>
                    </List>
                  </TabPanel>
                  <TabPanel>
                    <Heading size={"md"}>
                      Back-end Developer
                      <Text display={"inline"} color={usePinkMode()}>
                        {" "}
                        @ Loud
                      </Text>
                    </Heading>
                    <Text opacity={0.6}>Jul, 2021 - Jan, 2022</Text>
                    <List pt={"2"} spacing={3}>
                      <ListItem>
                        <ListIcon as={SmallCloseIcon} color={usePinkMode()} />
                        Typescript development;
                      </ListItem>
                      <ListItem>
                        <ListIcon as={SmallCloseIcon} color={usePinkMode()} />
                        Developed APIs and Scrappers;
                      </ListItem>
                      <ListItem>
                        <ListIcon as={SmallCloseIcon} color={usePinkMode()} />
                        Maintenance of PostgreSQL database;
                      </ListItem>
                      <ListItem>
                        <ListIcon as={SmallCloseIcon} color={usePinkMode()} />
                        Blockchain smart contracts development with solidity;
                      </ListItem>
                    </List>
                  </TabPanel>
                  <TabPanel>
                    <Heading size={"md"}>
                      Blockchain Engineer
                      <Text display={"inline"} color={usePinkMode()}>
                        {" "}
                        @ SNACKCLUB
                      </Text>
                    </Heading>
                    <Text opacity={0.6}>Jan, 2022 - Jan, 2023</Text>
                    <List pt={"2"} spacing={3}>
                      <ListItem>
                        <ListIcon as={SmallCloseIcon} color={usePinkMode()} />
                        Typescript development;
                      </ListItem>
                      <ListItem>
                        <ListIcon as={SmallCloseIcon} color={usePinkMode()} />
                        Developed APIs and Scrappers;
                      </ListItem>
                      <ListItem>
                        <ListIcon as={SmallCloseIcon} color={usePinkMode()} />
                        Maintenance of PostgreSQL database;
                      </ListItem>
                      <ListItem>
                        <ListIcon as={SmallCloseIcon} color={usePinkMode()} />
                        Blockchain smart contracts development with solidity;
                      </ListItem>
                    </List>
                  </TabPanel>
                  <TabPanel>
                    <Heading size={"md"}>
                      Software Engineer
                      <Text display={"inline"} color={usePinkMode()}>
                        {" "}
                        @ Bitboundaire
                      </Text>
                    </Heading>
                    <Text opacity={0.6}>Jan, 2023 - Present</Text>
                    <List pt={"2"} spacing={3}>
                      <ListItem>
                        <ListIcon as={SmallCloseIcon} color={usePinkMode()} />
                        Development of scrapers, data mining with Python;
                      </ListItem>
                      <ListItem>
                        <ListIcon as={SmallCloseIcon} color={usePinkMode()} />
                        Use of OpenAI tools and API for data analysis;
                      </ListItem>
                      <ListItem>
                        <ListIcon as={SmallCloseIcon} color={usePinkMode()} />
                        Frontend development with Next.js;
                      </ListItem>
                      <ListItem>
                        <ListIcon as={SmallCloseIcon} color={usePinkMode()} />
                        Use of database and Firebase tools;
                      </ListItem>
                      <ListItem>
                        <ListIcon as={SmallCloseIcon} color={usePinkMode()} />
                        Development of Neural Networks/Machine Learning;
                      </ListItem>
                    </List>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Center>
          </Box>
        </SlideFade>
      </Stack>
    </Container>
  );
};

export default WorkExperience;
