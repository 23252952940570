import { configureChains, createClient, goerli } from "wagmi";
import { publicProvider } from "wagmi/providers/public";

const { provider, webSocketProvider, chains } = configureChains(
  [goerli],
  [publicProvider()]
);

export const exportedChains = chains;

export const wagmiClient = createClient({
  provider,
  webSocketProvider,
});
