import { Button } from "@chakra-ui/react";
import { useAccount, useConnect } from "wagmi";
import { InjectedConnector } from "wagmi/connectors/injected";
import { truncateWallet } from "../utils";

const ConnectButton = () => {
  const { address, isConnected } = useAccount();
  const { connect } = useConnect({
    connector: new InjectedConnector(),
  });

  if (isConnected && address) {
    return (
      <Button variant="solid" colorScheme={"pink"}>
        {truncateWallet(address)}
      </Button>
    );
  }
  return (
    <Button onClick={() => connect()} variant="solid" colorScheme={"pink"}>
      Connect Wallet
    </Button>
  );
};

export default ConnectButton;
