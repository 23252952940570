import {
  Container,
  Heading,
  SlideFade,
  Stack,
  Text,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";

export const Home = () => {
  const headingOneFontSize = useBreakpointValue({ base: "3xl", md: "4xl" });
  const headingTwoFontSize = useBreakpointValue({ base: "2xl", md: "3xl" });
  const textFontSize = useBreakpointValue({ base: "lg", md: "xl" });
  return (
    <Container pt={["10", "180"]} minH={"calc(80vh)"} maxW={"80%"}>
      <Stack spacing={"5"}>
        <SlideFade in={true} transition={{ enter: { duration: 1 } }}>
          <Heading
            color={useColorModeValue("pink.500", "pink")}
            fontFamily={"Rokkitt"}
            size={"md"}
          >
            Hi, my name is
          </Heading>
        </SlideFade>
        <SlideFade
          in={true}
          transition={{ enter: { duration: 1, delay: 0.6 } }}
        >
          <Heading size={headingOneFontSize}>Rodrigo Henrique</Heading>
        </SlideFade>
        <SlideFade
          in={true}
          transition={{ enter: { duration: 1, delay: 0.7 } }}
        >
          <Heading opacity={"0.6"} size={headingTwoFontSize}>
            Software Engineer and Web3 enthusiast
          </Heading>
        </SlideFade>
        <SlideFade in={true} transition={{ enter: { duration: 1, delay: 1 } }}>
          <Text fontSize={textFontSize}>
            I'm a Computer Engineering student at Federal University of
            Uberlândia. Skilled in blockchain, node.js, and backend development.
            <br />
            Passionate about computer science, gaming and have been using
            computers since a very young age.
            <br />
            Always looking for new challenges and opportunities to improve my
            skills and knowledge.
          </Text>
        </SlideFade>
      </Stack>
    </Container>
  );
};

export default Home;
