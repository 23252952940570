import { MoonIcon, SunIcon } from "@chakra-ui/icons";
import { Button, Flex, useColorMode } from "@chakra-ui/react";
import ConnectButton from "./ConnectButton";
const Header = () => {
  const { colorMode, toggleColorMode } = useColorMode();
  return (
    <Flex justify={"flex-end"} padding={"5"} paddingRight={"7"} w={"100%"}>
      <ConnectButton />
      <Button marginLeft={2} onClick={toggleColorMode}>
        {colorMode === "light" ? <MoonIcon /> : <SunIcon />}
      </Button>
    </Flex>
  );
};

export default Header;
